
import React from "react"

import ListmanagerBlogtagPage from "../../components/negosyonow/listmanagers/blogtag";

const NNPARENTTAGID=52


const nnrecipepickerfields = [
	{"label":"Name", "dbfield": "nnrecipe_title", "type": "text", "filtertype": "textbox"},
	{"label":"Date", "dbfield": "nnrecipe_date", "type": "date", "filtertype": "date"},
	{"label":"Image", "dbfield": "nnrecipe_thumbnailimage", "type": "image", "filtertype": "image"},
];

const nnrecipepickerfilter = [
	{"field":"nnrecipetemplate_id", "operation": ">", "value": "0"},
	{"field":"nnrecipetemplate_id", "operation": "<", "value": "5"}
];

const searchFields = [
	{"label":"Order", "dbfield": "nntagblog_order", "type": "integer", "filtertype": "integer"},
	{"label":"Name", "dbfield": "nnrecipe_title", "type": "text", "filtertype": "text"},
	{"label":"Date", "dbfield": "nnrecipe_date", "type": "date", "filtertype": "date"},
	{"label":"Image", "dbfield": "nnrecipe_thumbnailimage", "type": "image", "filtertype": "image"},
];


const formFields = [
	[
		{"label":"Order", "field": "nntagblog_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Recipe", "field": "nnrecipe_title", "value": "", "input": "picker", "mode": "required", "pickerfields": nnrecipepickerfields, "pickerfilters": nnrecipepickerfilter},
		{"label":"Thumbnail", "field": "nnrecipe_thumbnailimage", "value": "", "input": "image", "mode": "readonly"},
	]
];




const FeaturedrecipePage = ({location}) => {


	return <ListmanagerBlogtagPage
			location={location}
			parenttagid={NNPARENTTAGID}
			formFields={formFields}
			searchFields={searchFields}
		/>
}


export default FeaturedrecipePage;
