
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps")

const ENTITYID="nntagblog"


const ListmanagerBlogtagPage = ({location, parenttagid, formFields, searchFields}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customDeleteDone(entity, pkid, payloadlist, token, subtablelist, callback)
	{
		negosyonowAPI.buildEntityId(parenttagid,token,"tag").then(nnresponse => {
			callback();
		});
	}

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//callback({"status":"Error", "message":"Debug"}); return;
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}

		params.nntag_id = parenttagid;

		if (params.hasOwnProperty(ENTITYID+"_order")) {
			webappsAPI.sortRecords(entity, token, ENTITYID+"_order", pkid, params[ENTITYID+"_order"], "nntag_id="+parenttagid, function(sortresponse) {
				saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);
			});
			return;
		}
		saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);
	}

	function saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback)
	{
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(parenttagid,token,"tag").then(nnresponse => {
					callback(response);
				});
			} else {
				callback(response);
			}
		});
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={true}
				allowdelete={true}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nntagblog_order"]}
				searchFields={searchFields}
				editFields={formFields}
				newformFields={formFields}
				customSubmit={customSubmit}
				customDeleteDone={customDeleteDone}
				userSearchFilter={[
					{"field":"nntag_id", "operation":"=", "value": parenttagid}
				]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default ListmanagerBlogtagPage;
